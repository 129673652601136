import { chatapi } from "../api/openaiService";
import mermaid from "mermaid";
import { checkIfHighchart, extractHighchart } from "./highchart";
import { checkIfMermaid, extractMermaidCode } from "./mermaid";

export const getRequestType = (input) => {
  const inputLowerCase = input.toLowerCase();

  if (checkIfMermaid(inputLowerCase)) return "mermaid";
  else if (checkIfHighchart(inputLowerCase)) return "highcharts";
  return "";
};

// When the user send a message we display it directly in the screen, and wait for the backend response
export const parseAndSetUserMessage = (
  data,
  setChatLog,
  input,
  setFormData,
  formData
) => {
  let reqType, userMessage;
  if (data === "submit") {
    reqType = getRequestType(input);
    userMessage = { role: "user", content: `${input}` };
  } else {
    reqType = getRequestType(data);
    userMessage = { role: "user", content: `${data}` };
  }
  setChatLog((chatLog) => [...chatLog, userMessage]);
  setFormData({ ...formData, input: "" });

  return { element: userMessage.content, reqType };
};

// This function send the user request to the backend and returns the response and store it the varisable result
export const handleRequest = async (
  data,
  element,
  reqType,
  conversationId,
  userId,
  lang,
  source
) => {
  let result;
    if (data !== "submit") {
      result = await chatapi(data, reqType, conversationId, source);
    } else {
      result = await chatapi(element, reqType, conversationId, source);
    }
  return result;
};



let str = "Compare our EBITDAR with our top 2 competitors";

let mySources = [
  "https://www.klmannualreport.com/wp-content/uploads/2023/04/KLM-Annual-Report-2024.pdf",
  "https://www.sasgroup.net/files/Main/290/3722605/sas-q1-report-2023.pdf",
  "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/norwegian-annual-report-2024.pdf",
];

export const parseAndSetBotResponse = async (
  result,
  element,
  setChatLog,
  errorSyntax,
  setIsLoading,
  cancelToken
) => {
  let mermaidCode, mermaidId, highchartId, highchartCode, reqType;
  let localMermaidError = false;
  let response = result.response;
  let responseArray = result.responses;
  if (response) {
    reqType = getRequestType(element);
    if (reqType === "mermaid") {
      mermaidCode = extractMermaidCode(response);
      const handleMermaidParsing = async () => {
        try {
          await mermaid.parse(mermaidCode);
          localMermaidError = false;
          mermaidId = mermaidCode ? `mermaid-${Date.now()}` : null;
        } catch (error) {
          localMermaidError = true;
          console.error("Error:", error);
        }
      };

      await handleMermaidParsing();
    } else if (reqType === "highcharts") {
      highchartId = `highchartId-${Date.now()}`;
      highchartCode = extractHighchart(response, highchartId);
    }
  }

  const responseId = Date.now();
  let preview =
    element === "Compare our EBITDAR with our top 2 competitors"
      ? mySources
      : result.sources
      ? result.sources
      : [];
  setChatLog((chatLog) => [
    ...chatLog,
    {
      role: "assistant",
      user: element,
      content:
        reqType === "highcharts" || reqType === "mermaid"
          ? response
          : responseArray,
      id: responseId,
      preview: preview,
      mermaidCode,
      imgUrl: result.image_url,
      mermaidError: localMermaidError,
      mermaidId,
      highchartCode,
      highchartError: errorSyntax[highchartId],
      highchartId,
      skeletons: result.sources ? result.sources.length : null,
      isSkeleton: result.sources ? true : false,
    },
  ]);

  setChatLog((chatLog) => {
    const updatedChatLog = chatLog.map((chat) => {
      if (chat.id === responseId) {
        return {
          ...chat,
          isSkeleton: false,
        };
      } else {
        return chat;
      }
    });
    return updatedChatLog;
  });

  setIsLoading(false);
};
