import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/output.css";
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@material-tailwind/react";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <HelmetProvider>
          <App />
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
