import React from 'react'

const ChatHeader = ({ t, isLoading, globalLang }) => {
    return (
        <div className="flex mb-6 items-center">
            <img className="w-[46px] h-[46px] mr-4 rounded-[4px]"
                src={`/assets/images/nr.png`}
                alt="nr airline pic" />
            <div className="w-full flex justify-between">
                <div className="flex flex-col" >
                    <h4 style={globalLang ? { direction: "rtl" } : {}}
                        className="text-[20px] font-apbold text-gray-900 leading-[32px]">{t("chatbox.h1")}</h4>
                    <p className="text-[14px] text-gray-400 leading-[20px]">
                        <span>{isLoading ? `${t("chatbox.p2")}` : `${t("chatbox.p1")}`}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ChatHeader