import { useContext, useState } from "react";
import RightElements from "../layouts/RightElements";
import { useTranslation } from "react-i18next";
import { languageContext } from "../../App";

const RightSection = ({ handleSubmit, setFormData, formData }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("");
  const { globalLang } = useContext(languageContext);

  const handleClick = async (data, e, example='') => {
    setFormData({ ...formData, input: data });
    setSelected(data);
    handleSubmit(data, e, example);
  };

  return (
    <section
      className="flex flex-col justify-between"
      style={{ height: "calc(100vh - 160px)" }}
    >
      <div
        className="flex flex-col h-full justify-between"
        style={globalLang ? { direction: "rtl" } : {}}
      >
        <div>
          <div className="flex items-center mb-[20px]">
            <span className={` ${globalLang ? "ml-[12px]" : "mr-[12px]"} mb-2`}>
              <img src="/assets/icons/brain.svg" alt="brain icon" />
            </span>
              <h3 className="text-blue-500 text-[24px] font-apbold leading-[30px] mb-[10px] flex">
                {t("right.h1")}
              </h3>
          </div>
          <RightElements
            handleClick={(data,e)=>handleClick(data,e,'h2')}
            selected={selected}
            text={t("right.h2")}
          />
          <RightElements
            handleClick={(data,e)=>handleClick(data,e,'h3')}
            selected={selected}
            text={t("right.h3")}
          />
          <RightElements
            handleClick={(data,e)=>handleClick(data,e,'h4')}
            selected={selected}
            text={t("right.h4")}
          />
          <RightElements
            handleClick={(data,e)=>handleClick(data,e,'h5')}
            selected={selected}
            text={t("right.h5")}
          />

        </div>
        <div className="flex justify-end">
          <img src="/assets/images/zuro.png" alt="zuro logo" className="" />
        </div>
      </div>
    </section>
  );
};
export default RightSection;
