import axios from "axios";

const makeApiCall = async (method, url, data = {}) => {
  try {
    const response = await axios({
      method,
      url,
      data,
    });
    return response.data;
  } catch (error) {
    console.log(error)
  }
};

export async function setConversationInfo(conversationId, payload) {
    const url = `${process.env.REACT_APP_BACKEND_URL}conversation/user-info?widget_id=${process.env.REACT_APP_WIDGET_ID}&conversation_id=${conversationId}`
    const ipAddress = await makeApiCall("get",`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_GEOKEY}`)
  
    return await makeApiCall("post",url,
      {
        "username":payload.userName,
        "email":payload.email,
        "location":ipAddress.country_name,
        "ip_address":ipAddress.ip,
        "phone":"Unknown",
        "browser":window.navigator.userAgent
      })
  }