
const ChatForm = ({t, globalLang, handleSubmit, formData, setFormData, cancelToken, isLoading}) => {
    const { input } = formData;


  return (
    <div className="mt-4" style={globalLang ? {direction:"rtl"} : {}}>
    <form className="relative" onSubmit={(e) => handleSubmit("submit", e)}>

        <input
        style={{boxShadow: "0px 1px 2px 0px #0000001A"}} 
        placeholder={t("chatbox.ph")} name="input"
            className="text-md bg-[#F1F1F1] 
            placeholder-gray-700 h-[62px] w-full px-[20px] py-[12px] outline-0"
            value={input} autoComplete="off"
            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        />

        <button 
            type="submit" 
            className={` ${globalLang ? 'left-[20px]' : 'right-[20px]'} 2xl:top-[12px] top-[8px] 
            2xl:bottom-[12px] bottom-[8px] flex items-center justify-center absolute`}
            ><svg className={`${globalLang ? 'rotate-180' : ''}`} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="15" fill="#D81939" />
                <g clipPath="url(#clip0_543_565)">
                    <path d="M8.1915 21.3611C8.07646 21.8897 8.48233 22.1135 8.85894 21.9447L21.5871 15.4181H21.5884C21.7402 15.3325 21.8284 15.1782 21.8284 15.0002C21.8284 14.822 21.7402 14.6676 21.5884 14.582H21.5871L8.85894 8.05537C8.48233 7.8865 8.07646 8.11035 8.1915 8.63901C8.19928 8.67482 8.95176 12.0267 9.36089 13.8496L16.0052 15.0001L9.36089 16.1505C8.95176 17.9733 8.19925 21.3252 8.1915 21.3611Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_543_565">
                        <rect width="14" height="14" fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
        </button>
        
       {isLoading && <button type="button" onClick={() => cancelToken.cancel()}
        className="absolute bottom-[80px] z-50 bg-gray-100 px-4 py-2 text-md rounded-[6px] -translate-x-1/2 left-[50%]" 
         >{t("chatbox.cancel")}</button>}
    </form>
</div>
  )
}

export default ChatForm