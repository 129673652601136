import { useEffect, useState } from "react";

const useLanguage = (i18n, getFlag, countries) => {
    
  const [selectedCountry, setSelectedCountry] = useState({
    name: i18n.language,
    flag: getFlag(
      countries,
      i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)
    ),
  });

  useEffect(() => {
    console.log(i18n.language);
    if (i18n.language === "en-US" || i18n.language === "en") {
      i18n.changeLanguage("english");
      setSelectedCountry({
        name: "English",
        flag: getFlag(countries, "en"),
      });
    } else if (i18n.language === "he-HE" || i18n.language === "he") {
      i18n.changeLanguage("hebrew");
      setSelectedCountry({
        name: "Hebrew",
        flag: getFlag(countries, "he"),
      });
    } else if (i18n.language === "nb" || i18n.language === "nb-NO" || i18n.language === "nb-SJ") {
      i18n.changeLanguage("norwegian");
      setSelectedCountry({
        name: "Norwegian",
        flag: getFlag(countries, "ng"),
      });
    } else if (i18n.language === "de") {
      i18n.changeLanguage("german");
      setSelectedCountry({
        name: "German",
        flag: getFlag(countries, "gr"),
      });
    } else if (i18n.language === "fr") {
      i18n.changeLanguage("french");
      setSelectedCountry({
        name: "French",
        flag: getFlag(countries, "fr"),
      });
    } else if (i18n.language === "ar-Ar" || i18n.language === "ar") {
      i18n.changeLanguage("arabic");
      setSelectedCountry({
        name: "Arabic",
        flag: getFlag(countries, "ar"),
      });
    } else if (i18n.language === "zh-TW") {
      i18n.changeLanguage("zh-TW");
      setSelectedCountry({
        name: "Chinese (Traditional)",
        flag: getFlag(countries, "zh-TW"),
      });
    } else if (i18n.language === "zh-CN" || i18n.language === "zh") {
      i18n.changeLanguage("zh-CN");
      setSelectedCountry({
        name: "Chinese (Simplified)",
        flag: getFlag(countries, "zh-CN"),
      });
    }
  }, [i18n, i18n.language]);

  return {selectedCountry, setSelectedCountry};
};

export default useLanguage;
