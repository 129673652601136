import { useEffect, useRef, useState } from "react";
import HighchartComponent from "../charts/HighchartComponent";
import MermaidComponent from "../charts/MermaidComponent";
import SourcesComponent from "../charts/SourcesComponent";
import DataChat from "./DataChat";

const ChatL = ({ isLoading,  errorSyntax,
  setErrorSyntax, chatLog,
  globalLang }) => {

  const chatLogContainerRef = useRef(null);
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
  const [showBox, setShowBox] = useState(false);

  useEffect(() => {
    chatLogContainerRef.current.scrollTop =
      chatLogContainerRef.current.scrollHeight;
  }, [chatLog, isLoading]);

  const calculateChatContainerHeight = () => {
    const screenWidth = window.innerWidth;
    let baseHeight;

    if (screenWidth <= 999) {
      baseHeight = 'calc(100vh - 380px)';
    } else if (screenWidth <= 1024) {
      baseHeight = 'calc(100vh - 410px)';
    } else if (screenWidth <= 1410) {
      baseHeight = 'calc(100vh - 310px)';
    } else {
      baseHeight = 'calc(100vh - 350px)';
    }
    return  baseHeight;
  };

  const handleClickWithMessage = (data, index) => {
    // Set the copied message index
    setCopiedMessageIndex(index);

    // Call the original handleClick function
    handleClick(data);

    // Reset the copied message index after 2 seconds
    setTimeout(() => setCopiedMessageIndex(null), 2000);
  }

  const handleClick = (data)=> {
    let copyText="";
    if(data.preview){
      const urls = data.preview.map((source) => source.url);
     copyText = data.content + '\n\n' + urls.join('\n');
    } else {
      copyText = data.content;
    }
    navigator.clipboard.writeText(copyText);
  }

  return (
    <div style={{ height: calculateChatContainerHeight() }}
      className=" overflow-y-auto flex-grow"
      ref={chatLogContainerRef}
    >
      {chatLog.map((data, index) => {
        return (
          <div key={index}>
            {data.role === "user" ? (
              <div className="flex items-end mt-4"
                 style={globalLang ? { direction: "rtl" } : {}}>
                <span>
                  <img
                    className="w-[46px] h-[46px] rounded-[4px]"
                    src="/assets/images/user.png"
                    alt="user"
                  />
                </span>
                <div className="rounded-tl-[12px] rounded-tr-[12px] rounded-br-[12px] 
                               min-h-[46px] h-auto max-w-xl bg-blue-500 ml-[12px]">
                  <p className="text-[16px] text-white leading-[24px] px-4 py-[12px]">
                    {data.content}
                  </p>
                </div>
              </div>
            ) : (
              <div>
                {/* It display the response we get, it is gonna be displayed only if there was no error, no highchart or mermaid */}
                {Array.isArray(data.content) ? (
                  data.content.map((item, num) => {
                    return (
                      <DataChat
                        key={num}
                        data={data}
                        message={item}
                        index={index}
                        globalLang={globalLang}
                        copiedMessageIndex={copiedMessageIndex}
                        handleClickWithMessage={handleClickWithMessage}
                      />
                    );
                  })
                ) : (
                  <DataChat
                    data={data}
                    message={data.content}
                    index={index}
                    globalLang={globalLang}
                    copiedMessageIndex={copiedMessageIndex}
                    handleClickWithMessage={handleClickWithMessage}
                  />
                )}
                {/* Error related to the server or backend */}
                {/*{data.errorEl && (*/}
                {/*  <div className="flex items-end justify-end mt-4">*/}
                {/*    <div*/}
                {/*      style={{*/}
                {/*        color: "red",*/}
                {/*        fontSize: "16px",*/}
                {/*        marginLeft: "2rem",*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      There seems to be an error - we are looking in and will*/}
                {/*      fix it ASAP*/}
                {/*    </div>*/}
                {/*    <img*/}
                {/*      className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"*/}
                {/*      src={`/assets/images/nr.png`}*/}
                {/*      alt="nr"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}
                {/* Error related to highchart if it has a syntax error */}
                {errorSyntax[data.highchartId] && (
                  <div className="flex items-end justify-end mt-4">
                    <div
                      style={{
                        color: "red",
                        fontSize: "16px",
                        marginLeft: "2rem",
                      }}
                    >
                      There seems to be an error - Please retry again
                    </div>
                    <img
                      className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
                      src={`/assets/images/nr.png`}
                      alt="nr"
                    />
                  </div>
                )}

                {/* Mermaid component that is going to be diplayed only if the user asks about a diagram */}
                <MermaidComponent
                  mermaidCode={data.mermaidCode}
                  mermaidError={data.mermaidError}
                  mermaidId={data.mermaidId}
                  chatLog={chatLog}
                />
                {/* Highchart component that is going to be diplayed only if the user asks about a highchart without any syntax error */}
                {data.highchartCode && !errorSyntax[data.highchartId] && (
                  <HighchartComponent
                    index={index}
                    data={data.highchartId}
                    setErrorSyntax={setErrorSyntax}
                    chatLog={chatLog}
                  />
                )}

                {/* This displays the image we get from midjourney */}
                {data.imgUrl && (
                  <div className="flex items-end justify-end mt-4">
                    <div className="rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] max-w-xl bg-gray-200">
                      <img
                        src={data.imgUrl}
                        alt="generated art"
                        className="w-full"
                      />
                    </div>
                    <img
                      className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
                      src={`/assets/images/nr.png`}
                      alt="midjourney generated pic"
                    />
                  </div>
                )}
                <SourcesComponent
                  data={data}
                  globalLang={globalLang}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default ChatL;
