import React from 'react'
import { ArrowRight } from '../Svgs/Svgs';

const RightElements = ({ handleClick, selected, text }) => {

  const isSelected = (input) => {
    return selected === input ? 'text-blue-500' : 'text-gray-800 font-normal';
  };


  return (
    <>
      <div onClick={(e) => handleClick(text, e)}
        className={` ${isSelected(text)} 
        arrowItem mb-[10px] flex items-center cursor-pointer py-[12px] px-[16px] bg-white`}>
       <ArrowRight />
        <h3 className="text-[16px] leading-[24px]">
          {text}</h3>
      </div>
    </>
  )
}

export default RightElements