export const highchartjsKeywords = [
    "chartjs",
    "chart.js",
    "bar chart",
    "line chart",
    "chart",
    "highchart",
    "highcharts",
    "highcharts.js",
    "highchartsjs",
    "highchartjs",
    "highchart editor",
    "ebitdar"
];

export const checkIfHighchart = (inputLowerCase) => {
    const isHighchartjsKeywords = highchartjsKeywords.some((keyword) =>
        inputLowerCase.includes(keyword)
    );
    return isHighchartjsKeywords;
}


// Here, we extract what is in between the script tag.
export const extractHighchart = (text, myId) => {
    const scriptRegex = /<script[^>]*>((.|[\n\r])*)<\/script>/im;
    const scriptMatch = text.match(scriptRegex);
    const script = scriptMatch ? scriptMatch[1] : null;
    if (script) {
        const updatedScript = script.replace("highchartId", myId);
        return updatedScript;
    }
    return null;
};