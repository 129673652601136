import mermaid from "mermaid";
import { useEffect } from "react";

const MermaidComponent = ({chatLog, mermaidCode, mermaidId, mermaidError }) => {


  // Displays and load mermaid functionalities
  useEffect(() => {
    if (chatLog.length > 0) {
      const lastItem = chatLog[chatLog.length - 1];
      if (lastItem.mermaidCode && lastItem.mermaidId) {
        try {
          mermaid.parse(lastItem.mermaidCode); // Check if the syntax is correct
          mermaid.initialize({
            startOnLoad: true,
          });
          mermaid.contentLoaded();
        } catch (error) {
          // Handle the syntax error
          console.error('Mermaid syntax error:', error);
        }
      }
    }
  }, [chatLog]);

  return (
    <>
      {mermaidCode && !mermaidError &&
        <div className="flex items-end justify-end mt-4">
          <div className="rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] bg-white">
            {mermaidId && <div className="mermaid" id={mermaidId}
              style={{ width: "100%", borderRadius: "10px" }} >{mermaidCode}</div>}
          </div>
          <img className=" ml-[12px] mr-[8px]"
            src={`/assets/images/nr.png`}
            alt="ai response pic" />
        </div>
      }
      {mermaidError && <div className="flex items-end justify-end mt-4">
        <div className="rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] max-w-xl bg-gray-200">
          <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'Lato' }} 
          className="text-[16px] font-normal text-gray-900 leading-[24px] px-4 py-[12px]">{mermaidCode}</pre>
        </div>
        <img className="ml-[12px] mr-[8px]"
          src={`/assets/images/nr.png`}
          alt="ai response pic" />
      </div>}
    </>
  )
}

export default MermaidComponent