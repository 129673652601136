import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {countries} from "../../config/constants";
import useLanguage from "../../hooks/useLanguage";
import {useState} from "react";
import SupportBtn from "./SupportBtn";

const ArrowDown = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4.99623C15.0007 5.12789 14.9754 5.25841 14.9254 5.3803C14.8755 5.50219 14.8018 5.61305 14.7087 5.70653L8.68312 11.7091C8.49496 11.8954 8.24043 12 7.97511 12C7.7098 12 7.45527 11.8954 7.2671 11.7091L1.24148 5.70653C1.07696 5.51515 0.990986 5.26897 1.00075 5.01719C1.01051 4.76541 1.11529 4.52656 1.29414 4.34839C1.473 4.17022 1.71276 4.06585 1.96551 4.05612C2.21826 4.0464 2.46539 4.13204 2.6575 4.29593L7.97009 9.58818L13.2827 4.29593C13.4225 4.15545 13.6011 4.05944 13.7957 4.02004C13.9904 3.98064 14.1924 3.99961 14.3762 4.07457C14.56 4.14953 14.7174 4.2771 14.8284 4.44115C14.9394 4.60521 14.9991 4.79837 15 4.99623Z"/>
    </svg>
  );
};

const Header = () => {
  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isClicked, setIsClicked] = useState(false);

  function getFlag(countries, selectedCountry) {
    const country = countries.find(function (country) {
      return (
        country.name === selectedCountry || country.code === selectedCountry
      );
    });
    return country ? country.flag : undefined;
  }

  const {selectedCountry, setSelectedCountry} = useLanguage(
    i18n,
    getFlag,
    countries
  );

  const handleClick = (code, name) => {
    setLanguage(code);
    i18n.changeLanguage(code);
    setSelectedCountry({name: name, flag: getFlag(countries, name)});
    setIsClicked(false);
  };
  return (
    <>
      <header className="bg-white h-[70px] mb-[40px]">
        <nav className="dashNav mx-auto flex justify-center items-center h-full 2xl:py-4 py-3 pr-[40px]">
          <div className="flex justify-between w-full items-center">

            <Link to="/">
              <img src="/assets/images/nr-logo-2.png" alt="osm thome logo"/>
            </Link>

            <div className="lg:flex hidden flex-col md:flex-row justify-end items-center">
              <a target="_blank" rel="noreferrer"
                 href="https://www.norwegian.com/"
                 className="mr-[15px] border-2 text-blue-500 border-blue-500 h-[40px] flex items-center
                 py-[10px] px-[20px] font-apbold text-[16px]"> {t('header.support')}</a>
              <a href="https://www.norwegian.com/us/help-contact/"
                 target="_blank" rel="noreferrer"
                 className="bg-red-500 h-[40px] flex items-center text-white
                py-[10px] px-[20px] font-apbold text-[16px]">{t('header.schedule')}</a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
