import React from "react";

export const ToolBar = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.985 10.813C15.429 10.822 14.986 11.275 14.997 11.832V19.414L13.874 18.291C13.686 18.098 13.43 17.989 13.159 17.988C12.606 17.987 12.158 18.433 12.157 18.986C12.157 19.257 12.267 19.518 12.464 19.709L15.292 22.537C15.684 22.925 16.313 22.925 16.702 22.537L19.536 19.709C19.919 19.31 19.906 18.675 19.507 18.292C19.119 17.921 18.508 17.92 18.12 18.291L16.997 19.418V11.832C17.008 11.267 16.549 10.807 15.985 10.813ZM31 21.325C31 24.348 28.535 26.815 25.516 26.815C19.543 26.815 13.572 26.815 7.6 26.815C3.958 26.815 1 23.849 1 20.203C1 16.932 3.493 14.455 6.609 13.928C6.916 9.214 10.56 5.583 15.926 5.185C21.506 5.583 25.471 9.46 25.471 14.471C25.47 14.934 25.346 15.379 25.397 15.833C28.536 15.833 31 18.3 31 21.325Z" />
      </svg>
    </>
  );
};
export const Copy = ({ handleClick, data, index }) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        onClick={() => handleClick(data, index)}
        className="cursor-pointer"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_37_167"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="5"
          width="22"
          height="22"
        >
          <path d="M27 5H5V27H27V5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_37_167)">
          <path
            d="M13.25 5.91669H23.3333C24.3416 5.91669 25.1666 6.74169 25.1666 7.75002V19.6667C25.1666 20.1709 24.7541 20.5834 24.25 20.5834C23.7458 20.5834 23.3333 20.1709 23.3333 19.6667V8.66669C23.3333 8.16252 22.9208 7.75002 22.4166 7.75002H13.25C12.7458 7.75002 12.3333 7.33752 12.3333 6.83335C12.3333 6.32919 12.7458 5.91669 13.25 5.91669ZM9.58329 9.58335H19.6666C20.675 9.58335 21.5 10.4084 21.5 11.4167V24.25C21.5 25.2584 20.675 26.0834 19.6666 26.0834H9.58329C8.57496 26.0834 7.74996 25.2584 7.74996 24.25V11.4167C7.74996 10.4084 8.57496 9.58335 9.58329 9.58335ZM10.5 24.25H18.75C19.2541 24.25 19.6666 23.8375 19.6666 23.3334V12.3334C19.6666 11.8292 19.2541 11.4167 18.75 11.4167H10.5C9.99579 11.4167 9.58329 11.8292 9.58329 12.3334V23.3334C9.58329 23.8375 9.99579 24.25 10.5 24.25Z"
            fill="#003251"
          />
        </g>
      </svg>
    </>
  );
};
export const Share = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        className="cursor-pointer"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_218_412)">
          <path d="M24.5284 9.33328C24.5284 10.8291 23.3159 12.0417 21.8201 12.0417C20.3243 12.0417 19.1118 10.8291 19.1118 9.33328C19.1118 7.83762 20.3243 6.625 21.8201 6.625C23.3159 6.625 24.5284 7.83762 24.5284 9.33328Z" />
          <path d="M21.8201 12.6667C19.9817 12.6667 18.4868 11.1717 18.4868 9.33328C18.4868 7.49506 19.9817 6 21.8201 6C23.6585 6 25.1534 7.49506 25.1534 9.33328C25.1534 11.1717 23.6585 12.6667 21.8201 12.6667ZM21.8201 7.25C20.671 7.25 19.7368 8.18506 19.7368 9.33328C19.7368 10.4817 20.671 11.4167 21.8201 11.4167C22.9692 11.4167 23.9034 10.4817 23.9034 9.33328C23.9034 8.18506 22.9692 7.25 21.8201 7.25Z" />
          <path d="M24.5284 22.6667C24.5284 24.1624 23.3159 25.375 21.8201 25.375C20.3243 25.375 19.1118 24.1624 19.1118 22.6667C19.1118 21.1709 20.3243 19.9583 21.8201 19.9583C23.3159 19.9583 24.5284 21.1709 24.5284 22.6667Z" />
          <path d="M21.8201 26C19.9817 26 18.4868 24.5049 18.4868 22.6667C18.4868 20.8283 19.9817 19.3333 21.8201 19.3333C23.6585 19.3333 25.1534 20.8283 25.1534 22.6667C25.1534 24.5049 23.6585 26 21.8201 26ZM21.8201 20.5833C20.671 20.5833 19.7368 21.5183 19.7368 22.6667C19.7368 23.8149 20.671 24.75 21.8201 24.75C22.9692 24.75 23.9034 23.8149 23.9034 22.6667C23.9034 21.5183 22.9692 20.5833 21.8201 20.5833Z" />
          <path d="M12.862 16C12.862 17.4958 11.6494 18.7083 10.1536 18.7083C8.65793 18.7083 7.44531 17.4958 7.44531 16C7.44531 14.5042 8.65793 13.2917 10.1536 13.2917C11.6494 13.2917 12.862 14.5042 12.862 16Z" />
          <path d="M10.1536 19.3333C8.31537 19.3333 6.82031 17.8384 6.82031 16C6.82031 14.1616 8.31537 12.6667 10.1536 12.6667C11.992 12.6667 13.487 14.1616 13.487 16C13.487 17.8384 11.992 19.3333 10.1536 19.3333ZM10.1536 13.9167C9.00446 13.9167 8.07031 14.8516 8.07031 16C8.07031 17.1484 9.00446 18.0833 10.1536 18.0833C11.3029 18.0833 12.237 17.1484 12.237 16C12.237 14.8516 11.3029 13.9167 10.1536 13.9167Z" />
          <path d="M12.1213 15.6001C11.8312 15.6001 11.5495 15.4492 11.3962 15.1792C11.1687 14.7801 11.3087 14.2709 11.7079 14.0425L19.4403 9.63419C19.8395 9.40501 20.3487 9.54508 20.5771 9.94578C20.8046 10.3449 20.6645 10.8541 20.2653 11.0826L12.5328 15.4908C12.4028 15.565 12.2612 15.6001 12.1213 15.6001Z" />
          <path d="M19.853 22.4751C19.7129 22.4751 19.5713 22.44 19.4413 22.3658L11.7088 17.9575C11.3096 17.73 11.1697 17.2209 11.3972 16.8208C11.6238 16.4208 12.1337 16.28 12.5338 16.5092L20.2664 20.9174C20.6655 21.145 20.8055 21.6541 20.5779 22.0542C20.4238 22.3242 20.1422 22.4751 19.853 22.4751Z" />
        </g>
        <defs>
          <clipPath id="clip0_218_412">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(6 6)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const Flag = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        className="cursor-pointer mx-[6px]"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.58333 25.7917C8.125 25.7917 7.75 25.4167 7.75 24.9583V8.49999C7.75 8.04166 8.125 7.66666 8.58333 7.66666C9.04167 7.66666 9.41667 8.04166 9.41667 8.49999V24.9583C9.375 25.4167 9.04167 25.7917 8.58333 25.7917Z" />
        <path d="M11.7083 19.8333C10.3333 19.8333 9.16667 19.375 8.04167 18.375C7.875 18.2083 7.75 18 7.75 17.75V8.29166C7.75 7.95832 7.95833 7.66666 8.25 7.54166C8.54167 7.41666 8.875 7.45832 9.125 7.66666C9.95833 8.37499 10.75 8.70832 11.7083 8.70832C12.9583 8.70832 14.2917 8.12499 15.6667 7.49999C17.1667 6.83332 18.6667 6.16666 20.2917 6.16666C21.6667 6.16666 22.8333 6.62499 23.9583 7.62499C24.125 7.79166 24.25 7.99999 24.25 8.24999V17.7083C24.25 18.0417 24.0417 18.3333 23.75 18.4583C23.4583 18.5833 23.125 18.5417 22.875 18.3333C22.0417 17.625 21.25 17.2917 20.2917 17.2917C19.0417 17.2917 17.7083 17.875 16.3333 18.5C14.8333 19.125 13.2917 19.7917 11.7083 19.8333ZM9.375 17.375C10.0833 17.9167 10.8333 18.2083 11.7083 18.2083C12.9583 18.2083 14.2917 17.625 15.6667 17C17.1667 16.3333 18.6667 15.6667 20.2917 15.6667C21.125 15.6667 21.875 15.8333 22.625 16.2083V8.66666C21.9167 8.12499 21.1667 7.83332 20.2917 7.83332C19.0417 7.83332 17.7083 8.41666 16.3333 9.04166C14.8333 9.70832 13.3333 10.375 11.7083 10.375C10.875 10.375 10.125 10.2083 9.375 9.83332V17.375Z" />
      </svg>
    </>
  );
};
export const ArrowRight = () => {
  return (
    <div className="w-[10px] h-[16px] mr-[10px]">
      <svg
        width="10"
        height="16"
        className=''
        viewBox="0 0 10 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 7.99999L2.17802 16L-9.73711e-08 13.7724L5.64396 7.99999L2.72238e-05 2.22759L2.17805 -4.67629e-07L10 7.99999Z"
        />
      </svg>
    </div>
  );
};
