import { useState } from "react";
import { Copy, Flag, Share } from "../Svgs/Svgs";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const DataChat = ({
  data,
  message,
  globalLang,
  copiedMessageIndex,
  index,
  handleClickWithMessage,
}) => {
  const [showBox, setShowBox] = useState(false);

  return (
    <>
      {data.content &&
        !data.errorEl &&
        !data.highchartCode &&
        !data.mermaidCode && (
          <div className="relative copyItem flex items-end justify-end mt-4">
            <div
              className="flex items-end justify-end"
              onMouseEnter={() => setShowBox(true)}
              onMouseLeave={() => setShowBox(false)}
            >
              <div
                style={globalLang ? { direction: "rtl" } : {}}
                className={`${showBox ? "bg-opacity-75" : ""} 
                   rounded-tl-[12px] rounded-tr-[12px] 
                      rounded-bl-[12px] max-w-xl bg-gray-200`}
              >
                <ReactMarkdown
                  children={message}
                  remarkPlugins={[remarkGfm]}
                  className="whitespace-pre-wrap text-[16px] font-normal text-gray-900 leading-[24px] px-4 py-[12px]"
                />
              </div>
              {copiedMessageIndex === index && (
                <div className="absolute top-[-30px] right-[160px]">
                  <span className="text-sm text-green-500">Copied!</span>
                </div>
              )}
              {showBox && (
                <div
                  className="flex absolute bg-white top-[-8px] right-[83px] rounded-[6px] 
                     px-[8px] py-[2px] border-2 border-[#BCBCBC]"
                >
                  <Copy
                    handleClick={handleClickWithMessage}
                    data={data}
                    index={index}
                  />
                  <Flag />
                  <Share />
                </div>
              )}
            </div>
            <img
              className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
              src={`/assets/images/nr.png`}
              alt="nr"
            />
          </div>
        )}
    </>
  );
};
export default DataChat;
