import { useState, useEffect, useContext } from "react";
import LeftSection from "../components/sections/LeftSection";
import RightSection from "../components/sections/RightSection";
import ChatL from "../components/sections/ChatL";
import ChatForm from "../components/sections/ChatForm";
import { handleRequest, parseAndSetBotResponse, parseAndSetUserMessage } from "../functions/submit";
import { useTranslation } from "react-i18next";
import { languageContext } from "../App";
import ChatHeader from "../components/layouts/ChatHeader";
import useEditLanguage from "../hooks/useEditLanguage";
import axios from 'axios';
import { setConversationInfo } from "../api/userInfoService";

const { CancelToken } = axios;


const ChatPage = () => {

    const [cancelToken, setCancelToken] = useState(null);
    const { t, i18n } = useTranslation();
    const [infoSent, setInfoSent] = useState(false);

    const [formData, setFormData] = useState({ input: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [currectLang, setCurrentLang] = useState(i18n.language === "en-US" ? "en" : i18n.language);
    const { globalLang } = useContext(languageContext);


    const [conversationId, setConversationId] = useState(0);
    const { input } = formData;
    const [chatLog, setChatLog] = useState([]);
    const [errorSyntax, setErrorSyntax] = useState({});

    useEditLanguage(i18n, setCurrentLang);

    const newConversationId = () => {
        setInfoSent(false);
        setConversationId(Math.floor(new Date() / 1000))
    }

    useEffect(() => {
        newConversationId()
    }, [])

    const newConversation = () => {
        window.location.href = "/";
    };


    const examples = {
        "h2": { "response": "<script>\nHighcharts.chart('highchartId', {    chart: {        \"height\": 400,        \"type\": \"column\"    },    credits: {        enabled: false    },    title: {        " +
              "text: \"EBITDAR (NOK million) data for last 6 years\"    },  " +
              "subtitle: {text: '',style: {display: 'none'}},    xAxis: {        categories: ['2017', '2020', '2021', '2022', '2023', '2024']    },    yAxis: {        title: {            text: \"EBITDAR (NOK million)\"        }    },    data: {        csv: \"\\\"Year\\\";\\\"EBITDAR (NOK million)\\\"\\n\\\"2019\\\";3948\\n\\\"2020\\\";2171\\n\\\"2021\\\";7313\\n\\\"2022\\\";4755\\n\\\"2023\\\";1516\\n\\\"2024\\\";1254\"    },    plotOptions: {        column: {            pointPadding: 0.2,            borderWidth: 0        }    },    series: [{        name: 'EBITDAR (NOK million)',        data: [5958, 3948, 2171, 7313, 4755, 1516, 1254]    }]});</script>",
            "sources": [
              "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2018-web-version.pdf",
              "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2019.pdf",
              "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2020.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/nas-annual-report-2021.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/norwegian-annual-report-2022.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/annual-general-meeting/2024/norwegian-annual-report-2023.pdf"
            ], "suggestions":[], "input_required":false, "initialize_socket":false
        },
        "h3": { "response": "<script>\nHighcharts.chart('highchartId', {    chart: {        \"height\": 400,        \"type\": \"column\"    },    credits: {        enabled: false    },    " +
              "title: {        text: \"EBITDAR (NOK million) data for last 5 years\"    },  subtitle: {text: '',style: {display: 'none'}},   xAxis: {        categories: ['2020', '2021', '2022', '2023', '2024']    },    yAxis: {        title: {            text: \"EBITDAR (NOK million)\"        }    },    data: {        csv: \"\\\"Year\\\";\\\"EBITDAR (NOK million)\\\"\\n\\\"2020\\\";2171\\n\\\"2021\\\";7313\\n\\\"2022\\\";4755\\n\\\"2023\\\";1516\\n\\\"2024\\\";1254\"    },    plotOptions: {        column: {            pointPadding: 0.2,            borderWidth: 0        }    },    series: [{        name: 'EBITDAR (NOK million)',        data: [5958, 3948, 2171, 7313, 4755, 1516, 1254]    }]});</script>",
            "sources": [
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2019.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2020.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/nas-annual-report-2021.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/norwegian-annual-report-2022.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/annual-general-meeting/2024/norwegian-annual-report-2023.pdf"
            ], "suggestions":[], "input_required":false, "initialize_socket":false
        },
        "h4": { "response": "<script>\nHighcharts.chart('highchartId', {    chart: {        \"height\": 400,        \"type\": \"column\"    },    credits: {        enabled: false    },    title: {       " +
              " text: \"Sales Revenue (NOK million) data for last 6 years\"    },  subtitle: {text: '',style: {display: 'none'}},   xAxis: {        categories: ['2017', '2020', '2021', '2022', '2023', '2024']    },    yAxis: {        title: {            text: \"Sales Revenue (NOK million)\"        }    },    data: {        csv: \"\\\"Year\\\";\\\"Sales Revenue (NOK million)\\\"\\n\\\"2019\\\";30948\\n\\\"2020\\\";40266\\n\\\"2021\\\";43522\\n\\\"2022\\\";9096\\n\\\"2023\\\";5068\\n\\\"2024\\\";18869\"    },    plotOptions: {        column: {            pointPadding: 0.2,            borderWidth: 0        }    },    series: [{        name: 'Sales Revenue (NOK million)',        data: [25951, 30948, 40266, 43522, 9096, 5068, 18869]    }]});</script>",
            "sources": [
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2018-web-version.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2019.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2020.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/nas-annual-report-2021.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/norwegian-annual-report-2022.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/annual-general-meeting/2024/norwegian-annual-report-2023.pdf"
            ], "suggestions":[], "input_required":false, "initialize_socket":false
        },
        "h5": { "response": "<script>\nHighcharts.chart('highchartId', {    chart: {        \"height\": 400,        \"type\": \"column\"    },   " +
              " credits: {        enabled: false    },    title: {      " +
              "  text: \"Sales Revenue (NOK million) data for last 5 years\"    },  subtitle: {text: '',style: {display: 'none'}},   xAxis: {        categories: ['2020', '2021', '2022', '2023', '2024']    },    yAxis: {        title: {            text: \"Sales Revenue (NOK million)\"        }    },    data: {        csv: \"\\\"Year\\\";\\\"Sales Revenue (NOK million)\\\"\\n\\\"2020\\\";40266\\n\\\"2021\\\";43522\\n\\\"2022\\\";9096\\n\\\"2023\\\";5068\\n\\\"2024\\\";18869\"    },    plotOptions: {        column: {            pointPadding: 0.2,            borderWidth: 0        }    },    series: [{        name: 'Sales Revenue (NOK million)',        data: [25951, 30948, 40266, 43522, 9096, 5068, 18869]    }]});</script>",
            "sources": [
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2019.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/annual-report-norwegian-2020.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/nas-annual-report-2021.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/reports-and-presentations/annual-reports/norwegian-annual-report-2022.pdf",
                "https://www.norwegian.com/globalassets/ip/documents/about-us/company/investor-relations/annual-general-meeting/2024/norwegian-annual-report-2023.pdf"
            ], "suggestions":[], "input_required":false, "initialize_socket":false
        }
    }

    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const giveExampleResponse = async (example) => {
        const resp = examples[example]
        if (resp) {
            await new Promise(resolve => setTimeout(resolve, randomIntFromInterval(2000,5000)))
            return resp
        }
        return undefined
    }

    const handleSubmit = async (data, e, example) => {
        // debugger
        e && e.preventDefault();
        setIsLoading(true);
        const { element, reqType } = parseAndSetUserMessage(data, setChatLog, input, setFormData, formData);

        if (cancelToken) {
            cancelToken.cancel();
        }
        const source = CancelToken.source();
        setCancelToken(source);
        try {
            // debugger
            const result = await giveExampleResponse(example) || await handleRequest(data, element, reqType, parseInt(conversationId), 108, currectLang, source);
            await parseAndSetBotResponse(result, element, setChatLog, errorSyntax, setIsLoading, source);
            if (!infoSent) {
                await setConversationInfo(conversationId, { userName: "Unknown", email: "Unknown" }).then(() => {
                    setInfoSent(true)
                });
            }

        } catch (error) {
            if (axios.isCancel(error)) {
                setIsLoading(false);
            } else {
                setChatLog(chatLog => [...chatLog, {
                    role: "assistant",
                    errorEl: true
                }]);
                setIsLoading(false);
                console.log(error);
            }

        }

    };


    return (
        <>
            <main className="mx-auto md:px-[40px] px-[12px]">
                <div className="flex  w-full lg:flex-row" >
                    {/* It is the left side that contains filters */}
                    {/*<LeftSection />*/}
                    <section
                      style={{
                          width: "80%"
                      }}
                      className="flex flex-col justify-between lg:w-3/5 w-full h-full">
                        <div className="flex flex-col h-full">
                            <div className="p-[20px] mx-[40px] mt-4 bg-white">
                                <ChatHeader
                                    t={t}
                                    isLoading={isLoading}
                                    globalLang={globalLang}
                                    newConversation={newConversation}
                                />
                                {/* Where we display the chat messages (what the user sent and the respose from backend) */}
                                <ChatL
                                    globalLang={globalLang}
                                    isLoading={isLoading}
                                    errorSyntax={errorSyntax}
                                    setErrorSyntax={setErrorSyntax}
                                    chatLog={chatLog}
                                />
                                {/* the form that has the submit function the send/receive requests/responses */}
                                <ChatForm
                                    t={t}
                                    globalLang={globalLang}
                                    handleSubmit={handleSubmit}
                                    isLoading={isLoading}
                                    formData={formData}
                                    cancelToken={cancelToken}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                    </section>
                    {/* the section in the right that has prompts */}
                    <section
                      style={{
                          width: "20%"
                      }}
                      className="flex flex-col justify-between h-full">
                        <RightSection handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} />
                    </section>
                </div >
            </main >
        </>
    )
}
export default ChatPage