import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const SourcesComponent = ({ globalLang, data }) => {
  const { i18n } = useTranslation();

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <>
      {!data.isSkeleton? (
        <>
          {!data.mermaidCode &&
            data.preview &&
            data.preview.length > 0 && (
              <>
                <div className="flex justify-end items-end mt-4">
                      <div className="flex flex-col justify-end items-end ">
                      {data.preview.map((source, index)=>{
                          return <div key={index} className={`flex items-center 
                          ${index === data.preview.length - 1 ? '' : 'mb-2'} bg-gray-200 
                          px-2 py-2 rounded-tl-[12px] rounded-tr-[12px] 
                          rounded-bl-[12px] max-w-xl`}>
                          <img src="/assets/icons/file.svg" className="mr-[12px] w-[30px] h-[30px]"/>
                          <a 
                          target="_blank"
                          href={source}
                          rel="noreferrer"
                          className="text-red-500 underline text-md underline">{source}</a>
                        </div>
                      })}
                      </div>
                      <img
                        className="ml-[12px] mr-[8px]"
                        src="/assets/images/nr.png"
                        alt="chatbot"
                      />
                    </div>
              </>
            )}
          {!data.mermaidCode &&
            !data.highchartCode &&
            data.pdfUrls &&
            data.pdfUrls.length > 0 && (
              <>
                <div className="flex items-end justify-end">
                  <div style={globalLang ? { direction: "rtl" } : {}}
                    className={`w-[300px] grid lg:grid-cols-2 grid-cols-1 gap-4`}
                  >
                    {data.pdfUrls.map((item, index) => {
                      return (
                        <a
                          key={index}
                          href={item}
                          rel="noreferrer"
                          target="_blank"
                          className={`${data.pdfUrls.length <= 2 ? "mr-2" : "direction-ltr"
                            } mt-4`}
                        >
                          <div
                            className={`flex items-end justify-end flex-col rounded-lg max-w-xl`}
                          >
                            <img
                              className="object-cover w-[65px] rounded-lg"
                              src="/assets/images/pdf.png"
                              alt="pdf file"
                            />
                          </div>
                        </a>
                      );
                    })}
                  </div>
                  <img
                    className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
                    src="/assets/images/nr.png"
                    alt="nr worker"
                  />
                </div>
              </>
            )}
        </>
      ) : (<></>)}
    </>
  );
};

export default SourcesComponent;
